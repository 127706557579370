<template>
  <div class="contents">
    <div class="container-fluid">
      <div class="profile-content mb-50">
        <div class="row">
          <div class="col-lg-12 mb-2 sticky">
            <div class="breadcrumb-main p-3" style="background-color: #f4f5f7 !important">
              <h4 class="text-capitalize breadcrumb-title">
                Normalisation
                <ul class="atbd-breadcrumb nav">
                  <li class="atbd-breadcrumb__item">
                    <router-link to="/">
                      <span class="la la-home"></span>
                    </router-link>
                    <span class="breadcrumb__seperator">
                      <span class="la la-slash"></span>
                    </span>
                  </li>
                  <li class="atbd-breadcrumb__item">
                    <span class="bread-item" style="color: #9299b8">Questions</span>
                    <span class="breadcrumb__seperator">
                      <span class="la la-slash"></span>
                    </span>
                  </li>
                  <li class="atbd-breadcrumb__item">
                    <span class="bread-item">Normalisation Detail</span>
                  </li>
                </ul>
              </h4>

            </div>
          </div>
          <div class="col-lg-12">
            <div class="projects-tab-content mb-30">
              <div class="row">

                <!-- ends: col-->
                <div class="col-xl-9 col-lg-9 mb-25">
                  <div class="card h-100">
                    <div class="card-header py-sm-20 py-3  px-sm-25 px-3 ">
                      <h6>Info</h6>
                    </div>
                    <div class="card-body">
                      <div class="about-projects">
                        
                        <ul class="d-flex text-capitalize mt-0">
                          <li class="col-md-2">
                            <span class="color-light fs-13">Subject</span>
                            <p class="color-dark fs-14 mt-1 mb-0 fw-500">{{ document.meta.subject }}</p>
                          </li>
                          <li>
                            <span class="color-light fs-13">Topic</span>
                            <p class="color-primary fs-14 mt-1 mb-0 fw-500">{{ document.meta.topic }}</p>
                          </li>
                        </ul>
                        <ul class="d-flex text-capitalize">
                          <li class="col-md-2">
                            <span class="color-light fs-13">Added by</span>
                            <p class="fs-14 fw-600 color-dark mb-0">
                              <router-link
                                :to="{ name: 'officers-profile', params: { officer: { ulid: document.meta.username } } }">{{
                                    document.meta.username
                                }}</router-link>
                            </p>
                          </li>
                          <li>
                            <span class="color-light fs-13">Date</span>
                            <p class="color-primary fs-14 mt-1 mb-0 fw-500">{{ document.data.added }}</p>
                          </li>
                        </ul>
                        <ul class="d-flex text-capitalize">
                          <li class="col-md-6">
                            <span class="color-light fs-13">File</span>
                            <p class="color-dark fs-14 mt-1 mb-0 fw-500">{{ document.data.file }}</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- ends: col -->
                </div>
                <!-- ends: .card -->
                <div class="col-xl-3 col-lg-3 col-12 mb-25">
                  <div class="card h-100">
                    <div class="card-header d-flex justify-content-between align-items-center py-10  px-sm-25 px-3">
                      <h6 class="fw-500 ">Normalisers</h6>
                    </div>
                    <div class="card-body">
                      <div class="d-flex align-items-center mb-25">
                        <img src="@/assets/img/custom/avatar-2.png" class="wh-46 mr-15" alt="img">
                        <div>
                          <span class=" mt-1 fs-14  color-light ">Claimed by</span>
                          <!-- <p class="fs-14 fw-600 color-dark mb-0">{{ document.meta.claimer }}</p> -->
                          <p class="fs-14 fw-600 color-dark mb-0" v-if="document.meta.claimer">
                              <router-link
                                :to="{ name: 'officers-profile', params: { officer: { ulid: document.meta.claimer } } }">{{
                                    document.meta.claimer
                                }}</router-link>
                            </p>
                            <p class="color-dark fs-14 mt-1 mb-0 fw-500" v-else>None</p>
                        </div>

                      </div>
                      <div class="d-flex align-items-center mb-25">
                        <img src="@/assets/img/custom/avatar-3.png" class="wh-46 mr-15" alt="img">
                        <div>
                          <span class=" mt-1 fs-14  color-light ">Approved by</span>
                          <!-- <p class="fs-14 fw-600 color-dark mb-0">{{ document.meta.approver }}</p> -->
                          <p class="fs-14 fw-600 color-dark mb-0" v-if="document.meta.claimer">
                              <router-link
                                :to="{ name: 'officers-profile', params: { officer: { ulid: document.meta.approver } } }">{{
                                    document.meta.approver
                                }}</router-link>
                            </p>
                            <p class="color-dark fs-14 mt-1 mb-0 fw-500" v-else>None</p>
                        </div>
                      </div>

                    </div>
                  </div>
                  <!-- ends: .card -->
                </div>
                <!-- ends: col -->

                <!-- ends: col -->
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="contact-list-wrap mb-25">
                  <div class="contact-list bg-white radius-xl w-100">

                    <div class="mb-2">
                      <h3> <small> Total Questions </small> {{questionInfo.total}} </h3>
                    </div>

                    <div class="table-responsive">
                      <LoaderOverlay :active="isLoading" :fullpage="true" bgColor="#ffffff55" zIndex="99999"></LoaderOverlay>
                      <table class="table mb-0 table-hover table-borderless border-0">
                        <thead>
                          <tr class="userDatatable-header">
                            <th>
                              <span class="userDatatable-title">No</span>
                            </th>
                            <th>
                              <span class="userDatatable-title">Questions</span>
                            </th>
                            <th>
                              <span class="userDatatable-title">Type</span>
                            </th>
                            <th>
                              <span class="userDatatable-title">Version</span>
                            </th>
                            <th>
                              <span class="userDatatable-title">Status</span>
                            </th>
                            
                            <th>
                              <span class="userDatatable-title float-right">Actions</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(question, k) in questions" :key="k">

                            <td>
                              <div>
                                {{ k + 1 }}
                              </div>
                            </td>
                            <td>
                              <div class="orderDatatable-title"
                                v-html="question.description.substring(0, 61) + '<br>' + question.description.substring(61, question.description.length)">

                              </div>
                            </td>
                            <td>
                              <div v-if="question.type == 2" class="userDatatable-content">
                                Simple
                              </div>
                              <div v-else-if="question.type == 1" class="userDatatable-content">
                                Dichotomous
                              </div>
                              <div v-else-if="question.type == 4" class="userDatatable-content">
                                Multi
                              </div>
                              <div v-else-if="question.type == 8" class="userDatatable-content">
                                Rank
                              </div>
                              <div v-else-if="question.type == 16" class="userDatatable-content">
                                Open
                              </div>
                            </td>
                            <td>
                              <div class="userDatatable-content d-inline-block">
                                <span v-if="question.version"
                                  class="bg-opacity-primary color-primary rounded-pill userDatatable-content-status active">{{ question.version }}</span>
                              </div>
                            </td>
                            <td>
                              <div v-if="question.status == 1" class="orderDatatable-status d-inline-block">
                                <span class="order-bg-opacity-success  text-success rounded-pill active">Approved</span>
                              </div>
                              <div v-if="question.status == 0 && question.level"
                                class="orderDatatable-status d-inline-block">
                                <span class="order-bg-opacity-warning  text-warning rounded-pill active">Awaiting
                                  Approval</span>
                              </div>
                              <div v-else-if="question.status == 0 && !question.level"
                                class="orderDatatable-status d-inline-block">
                                <span class="order-bg-opacity-info  text-info rounded-pill active">Pending</span>
                              </div>
                              <div v-if="question.status == -1" class="orderDatatable-status d-inline-block">
                                <span
                                  class="order-bg-opacity-danger  text-danger rounded-pill active">Disapproved</span>
                              </div>
                            </td>
                            <td>
                              <ul class="orderDatatable_actions mb-0 d-flex flex-wrap float-right">
                                <li>
                                  <a class="link" @click="questionAdd(question, topicName(question.tolid), true)" v-tooltip:top="'Edit Question'">
                                    <i class="las la-edit"></i>
                                  </a>
                                </li>
                                <li :class="{ 'el-fade disabled': question.level != 2048 }" v-tooltip:bottom="'Review Question'">
                                  <a class="link" @click="questionView(question, true, question.level != 2048)">
                                    <i class="las la-door-open"></i>
                                  </a>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ends: col-12 -->
            </div>
          </div>
          

        </div>

      </div>
      <div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiClient } from "@/plugins/restclient.js";
import LoaderOverlay from "@/components/LoaderOverlay"
export default {
  components:{
    LoaderOverlay
  },
  data() {
    return {
      document: {},
      questions: [],
      questionInfo:{},
      isLoading:false
    }
  },
  created() {
    this.document = this.$route.params
    if(!this.document.data) return this.$router.push('/')
    this.getQuestion()
  },
  methods: {
    getQuestion() {
      var ts = Math.round(+new Date() / 1000);

      this.isLoading = true;
      const headers = {
        "X-Timestamp": ts,
        "X-Ulid": `${this.$store.state.user.ulid}`,
      };
      apiClient.get(`/v1/office/assessment/question/summon?normal=${this.document.data.nolid}`,{ headers: headers }).then(res => {
        this.questions = res.data.data
        this.questionInfo = res.data.info
        this.isLoading = false
      }).catch(()=>{
        this.isLoading = false
      })
    }
  }
}
</script>

<style>

</style>